import './App.css';
import logo from './components/imagine/logo_lingua_ignota.svg';
import logoTitle from './components/imagine/logo_lingua_ignota_text.svg';

function App() {
  return (
    <div className='background'>
        <img src={logo} className='logo'></img>
        <div className='containerTitle'>
          <img src={logoTitle} className="logoTitle"></img>
          <h3>The Project is in Development</h3>
          <h3>It will be released at the beginning of September 2021</h3>
          <p><a className='retLink' href='https://www.wamberger.eu'>Return to wamberger.eu</a></p>
          <p>&copy; {new Date().getFullYear()} Wamberger</p>
        </div>
    </div>
  );
}

export default App;
