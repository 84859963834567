import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Suspense } from 'react';
import { Spinner } from 'react-bootstrap'; 
import reportWebVitals from './reportWebVitals';

const loadStyle = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#000000',
  color: '#ffffff',
}
  
const loading = <div style={loadStyle}><Spinner animation="border" />Loading</div>

ReactDOM.render(
  <Suspense fallback={loading}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

reportWebVitals();
